














































































































import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import { MAX_ITEMS_PER_PAGE, RunQueryOptions } from '@/module/api/common';
import { useDomainApi } from '@/module/api/domain';
import { DomoModel, useDomoApi } from '@/module/api/domo';
import { useExportApi } from '@/module/api/export';
import { DateTimeMethod, formatDbDate, saveTextToClipboard, usePagination } from '@/utils';
import DomainDialog from '@/views/app/domains/DomainDialog.vue';
import { computed, defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';

const {
  areThereMorePages,
  getNextPageQueryParams,
  emptyPageableTable,
  resetPageableTable,
  cachePage,
  applyPageChange,
} = usePagination(MAX_ITEMS_PER_PAGE);

const toast = createToastInterface({ maxToasts: 3 });
const { selectedItem: selectedDomain, isLoading: isDomainLoading, refreshItem: refreshDomain } = useDomainApi();

const {
  selectedItem: selectedExportJob,
  isLoading: isExportLoading,
  selectedItem: selectedExport,
  refreshItem: refreshExport,
} = useExportApi();

const { getItems: getDomos, items: domos, isLoading: isDomoLoading } = useDomoApi();

const domainDialog = ref<any>();

const emptyState = () => {
  return {
    dialog: false,
    exportUuid: '',
    domainUuid: '',
    domoSetUuid: '',
    filter: [] as Partial<Record<string, any>>[],
    systemFilters: { 'filter[domoSetUuid]': '', 'sort[operationType]': 1, 'sort[entityType]': 1 },
    domoDetailsDialog: false,
    data: [] as Partial<DomoModel[]>,
    currentDomo: {} as Partial<DomoModel>,
  };
};

const state = reactive(emptyState());

const domoTable = reactive({
  ...emptyPageableTable(),
  headers: [
    { text: '', value: 'uuid', width: '32px', sortable: false },
    { text: 'Type', value: 'entityType', sortable: false },
    { text: 'Name', value: 'dombName', sortable: false },
    { text: 'ID', value: 'entityId', sortable: false },
    { text: 'Parent ID', value: 'parentEntityId', sortable: false },
  ],
  itemsPerPage: MAX_ITEMS_PER_PAGE,
});

const viewDomain = () => {
  Vue.$log.debug('Clicked view dialog');
  domainDialog.value.view(selectedDomain.value);
};

const addFilter = (filter: any[]) => {
  filter.push({ key: '', value: '' });
};

const filterAsQueryParams = computed(() => {
  return state.filter.reduce((query: any, rec: any) => {
    if (rec.key && rec.key !== '' && rec.value) {
      query[rec.key] = rec.value;
    }
    return query;
  }, {});
});

const queryParamFormattedFilter = computed(() => {
  return state.filter.map(rec => {
    return { [rec.key]: rec.value };
  });
});

const getDomoSearchResults = async () => {
  const getDomosRes = await getDomos({
    query: { ...filterAsQueryParams.value, ...state.systemFilters },
    raw: true,
  });
  resetPageableTable(domoTable);
  const areMorePages = areThereMorePages(getDomosRes);
  if (areMorePages) {
    domoTable.pageCount = 2;
  }
  domoTable.data = getDomosRes._embedded;
  cachePage(domoTable, domoTable.data);
};

const addDefaultFilters = () => {
  Vue.$log.debug('When adding default filters, this is our domain and state', state, selectedDomain);
  if (state.domoSetUuid !== '' && state.systemFilters['filter[domoSetUuid]'] !== state.domoSetUuid) {
    state.systemFilters['filter[domoSetUuid]'] = state.domoSetUuid;
  }
};

const refreshItems = async (forceUpdate = false): Promise<void> => {
  await refreshDomain(state.domainUuid, forceUpdate);
  await refreshExport(state.exportUuid, forceUpdate);

  if (forceUpdate || state.domoSetUuid !== selectedExport.value?.domoSetUuid) {
    state.domoSetUuid = selectedExport.value.domoSetUuid ?? '';
  }

  if (!state.domoSetUuid) {
    Vue.$log.error(
      'Could not refresh all items. Unable to derive domoSetUuid from queried exports. Returning without refreshing domos',
    );
    return;
  }
  addDefaultFilters();
  await getDomoSearchResults();
};

const queryDomos = async (runQueryOptions: RunQueryOptions) => {
  const pageQuery = runQueryOptions.nextPage
    ? getNextPageQueryParams({
        page: domoTable.page,
      })
    : { page: domoTable.page };
  pageQuery.limit = 50;
  const getParams = {
    query: {
      ...pageQuery,
      ...filterAsQueryParams.value,
      ...state.systemFilters,
    },
    raw: true,
  };
  const response = await getDomos(getParams);
  domoTable.data = response?._embedded;
  cachePage(domoTable, domoTable.data);
  const areMorePages = areThereMorePages(response);
  if (areMorePages) {
    domoTable.pageCount = domoTable.pageCount + 1;
  }
  return domoTable.data;
};

const queryDomosWithFilter = () => {
  getDomos({ ...filterAsQueryParams.value, ...state.systemFilters });
};

const showDomoDetailDialog = (item: DomoModel) => {
  state.currentDomo = item;
  state.domoDetailsDialog = true;
};

const getNextPage = async () => {
  applyPageChange(domoTable, async () => await queryDomos({ nextPage: true }));
};

const reset = async () => {
  Object.assign(state, emptyState());
};

const clipyboardCopyWithNotification = (textToCopy: string) => {
  saveTextToClipboard(textToCopy);
  toast.success('Copied text to clipboard.');
};

const jsonPretty = (obj: any, isUserAdmin: boolean) => {
  if (!isUserAdmin) {
    // Non-admins shouldn't see the whole things
    return JSON.stringify(
      {
        uuid: obj.uuid,
        name: obj.dombName,
        id: obj.entityId,
        parentId: obj.parentEntityId,
        type: obj.entityType,
        operation: obj.operationType,
      },
      null,
      2,
    );
  }
  return JSON.stringify(obj, null, 2);
};

export default defineComponent({
  name: 'DomainObjectBrowser',
  components: { RefreshButton, DomainDialog, DomainWorkspaceToolbar },
  props: {
    uuid: {
      type: String,
    },
    jobUuid: {
      type: String,
    },
  },
  setup(props) {
    reset();
    // TODO: Need to allow domainObjectBrowser to accept load or export job prop instead of just job
    state.exportUuid = props.jobUuid || '';
    state.domainUuid = props.uuid || '';
    refreshItems();
    return {
      ...toRefs(state),
      reset,
      selectedExportJob,
      formatDbDate,
      refreshItems,
      isUserAdmin: true,
      domos,
      domainDialog,
      viewDomain,
      isDomoLoading,
      isDomainLoading,
      isExportLoading,
      applyPageChange,
      domoTable,
      addFilter,
      saveTextToClipboard,
      queryParamFormattedFilter,
      DateTimeMethod,
      queryDomos,
      queryDomosWithFilter,
      jsonPretty,
      clipyboardCopyWithNotification,
      jobType: 'Import',
      backLink: `/domain/${props.uuid}/job/export/${props.jobUuid}`,
      showDomoDetailDialog,
      getDomoSearchResults,
      menu: [
        {
          title: 'Export History',
          link: `/domain/${props.uuid}/workspace/export-jobs`,
        },
        { title: 'Download Object List', link: '' },
        { title: 'Delete Objects', link: '' },
        { title: 'Restore Domain', link: '' },
      ],
      commonFilters: [
        'filter[entityType]',
        'filter[entityId]',
        'filter[parentEntityId',
        'filter[dombName]',
        'filter[state]',
        'filter[uuid]',
      ],
      getNextPage,
    };
  },
});
